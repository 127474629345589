import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "footerWrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", {
      class: "imprint",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.footerClicked('imprint')))
    }, "Impressum"),
    _createElementVNode("p", {
      class: "privacy",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.footerClicked('privacy')))
    }, "Datenschutz")
  ]))
}