
import {Vue, Options} from "vue-class-component";

@Options({
    emits: ["close"]
})
export default class Imprint extends Vue {
    closeDialog(): void{
        this.$emit('close');
    }
}
