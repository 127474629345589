
import { Options, Vue } from 'vue-class-component';
import Menu from "@/components/Menu.vue";
import Start from "@/components/Start.vue";
import About from "@/components/About.vue";
import Product from "@/components/ProductSection.vue";
import Contact from "@/components/Contact.vue";
import Footer from "@/components/Footer.vue";
import Imprint from "@/components/Imprint.vue";
import Privacy from "@/components/Privacy.vue";
import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import {fetchAndActivate, getRemoteConfig, getValue} from "firebase/remote-config";
import rcDefaults from "../remote_config_defaults.json";

@Options({
  components: {
      Privacy,
      Imprint, Menu, Start,
      About, Product, Contact, Footer,
  },
})
export default class App extends Vue {
    public showImprint = false;
    public showPrivacy = false;
    public aboutText = "";
    public availableText = "";
    public productText = "";

    private insertStringsFromFirebase(){
        const remoteConfig = getRemoteConfig();
        this.aboutText = getValue(remoteConfig, "AboutText").asString();
        this.availableText = getValue(remoteConfig, "AvailableText").asString();
        this.productText = getValue(remoteConfig, "ProductText").asString() + "<br><br>" + getValue(remoteConfig, "ErnteSlogan").asString();
    }


    beforeMount() {
        const remoteConfig = this.initFirebase();
        this.insertStringsFromFirebase();
        fetchAndActivate(remoteConfig)
            .then(() => this.insertStringsFromFirebase())
            .catch(() => {/* firebase fetch error */});
    }

    private initFirebase(){
        const firebaseConfig = {
            apiKey: "AIzaSyDrrCQ1Vr4XMXZcgQD5cGyDRw5BMO5Wf2I",
            authDomain: "sauerland-heu.firebaseapp.com",
            databaseURL: "https://sauerland-heu-default-rtdb.europe-west1.firebasedatabase.app",
            projectId: "sauerland-heu",
            storageBucket: "sauerland-heu.appspot.com",
            messagingSenderId: "870086214638",
            appId: "1:870086214638:web:36ac761799bcfe869604ff",
            measurementId: "G-B4WLNQK6CQ"
        };

        // Initialize Firebase
        const app = initializeApp(firebaseConfig);
        getAnalytics(app);
        const remoteConfig = getRemoteConfig(app);
        remoteConfig.defaultConfig = rcDefaults;
        return remoteConfig;
    }

    footerClicked(what: string): void{
        switch (what){
            case "imprint":
                this.showImprint = true;
                break;
            case "privacy":
                this.showPrivacy = true;
                break;
        }
    }
}
