
import { Vue, Options} from "vue-class-component";

@Options({
    emits: ["close"]
})
export default class Privacy extends Vue{
    closeDialog(){
        this.$emit('close');
    }
}
