
import {Options, Vue} from "vue-class-component";

  @Options({})
  export default class Menu extends Vue {
      public handleSelect(key: string): void{
          document.getElementById(key)?.scrollIntoView({behavior: "smooth"});

          this.$nextTick(() => {
              const menuItems = document.getElementsByClassName("el-menu-item");
              for(const menuItem of menuItems){
                  menuItem.classList.remove("is-active");
              }
              const startItem = document.getElementById("startItem");
              startItem?.classList.add("is-active");
              (document.activeElement as HTMLElement)?.blur();
          });
      }
  }
