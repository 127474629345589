
import {Vue, Options} from "vue-class-component";
import {ElForm} from "element-plus";
import {reactive, ref} from "vue";
import { VueRecaptcha } from "vue-recaptcha";
import ContactUtil from "../utils/ContactUtil";

type FormInstance = InstanceType<typeof ElForm>

@Options({
    components: {
        VueRecaptcha
    },

})
export default class Contact extends Vue{
    private recaptchaResponse = "";
    private showCaptchaWarning = false;
    private showFormError = false;
    private formLoading = false;
    private showSuccess = false;
    private labelPosition = "top";

    public rules = reactive({
        first_name: [{
                required: true,
                message: 'Bitte geben Sie Ihren Vornamen an.',
                trigger: 'blur',
        }],
        last_name: [{
                required: true,
                message: "Bitte geben Sie Ihren Nachnamen an.",
                trigger: "blur",
        }],
        email: [
            {
                required: true,
                message: 'Bitte geben Sie Ihre E-Mail Adresse an.',
                trigger: 'blur',
            }, {
                type: 'email',
                message: "Bitte geben Sie eine gültige E-Mail Adresse an.",
                trigger: 'blur'
            }
        ],
        message: [{
                required: true,
                min: 10,
                message: "Bitte geben Sie mindestens 10 Zeichen ein.",
                trigger: 'blur'
        }],
    });
    public contactForm = reactive({
        first_name: '',
        last_name: '',
        email: '',
        message: '',
    });
    public formRef = ref<FormInstance>();

    public submitForm = (formEl: FormInstance | undefined) => {
        if(!formEl) return;
        formEl.validate(valid => {
            if(!valid) return;
        
            if(this.recaptchaResponse === "") 
                return;
    
            this.formLoading = true;
            ContactUtil.submitContactForm(this.contactForm.first_name, this.contactForm.last_name,
                        this.contactForm.email, this.contactForm.message, this.recaptchaResponse).then(() => {
                this.showSuccess = true;
                this.cleanupForm();
            }).catch(() => {
                this.showFormError = true;
                this.cleanupForm();
            });
        });
    };
    private cleanupForm(){
        this.contactForm.first_name = '';
        this.contactForm.last_name = '';
        this.contactForm.email = '';
        this.contactForm.message = '';
        this.recaptchaResponse = '';
        this.showCaptchaWarning = false;
        this.formLoading = false;
    }

    public verifyCaptcha(response: string){
        this.recaptchaResponse = response;
        this.showCaptchaWarning = false;
    }
    public captureExpired(){
        this.recaptchaResponse = "";
    }

    mounted(){
        this.labelPosition  = window.innerWidth < 680 ? "top" : "left";
        window.onresize = () => {
            this.labelPosition  = window.innerWidth < 680 ? "top" : "left";
        }
    }
}
