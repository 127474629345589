
import {Vue, Options} from "vue-class-component";

@Options({
    emits: ['footerClicked'],
})
export default class Footer extends Vue {
    private footerClicked(clicked: string){
        this.$emit('footerClicked', clicked);
    }
}

