const apiEndpoint = "https://api.sauerland-heu.de/submit_contact.php";

export default class Contactutil {

    public static submitContactForm(first_name: string, last_name: string, mail: string, message: string, captchaCode: string): Promise<void>{
        const formData = new URLSearchParams();
        formData.append("first_name", first_name);
        formData.append("last_name", last_name);
        formData.append("mail", mail);
        formData.append("message", message);
        formData.append("captcha_code", captchaCode);
        
        return fetch(apiEndpoint, {
            method: 'POST',
            body: formData,
        }).then(response => {
            if(!response.ok)
                throw new Error("Could not send contact form");
        });
      
    }
}

