import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_menu_item = _resolveComponent("el-menu-item")!
  const _component_el_menu = _resolveComponent("el-menu")!

  return (_openBlock(), _createBlock(_component_el_menu, {
    "default-active": "start",
    class: "menu",
    mode: "horizontal",
    onSelect: _ctx.handleSelect
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_menu_item, {
        index: "start",
        id: "startItem"
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Start ")
        ]),
        _: 1
      }),
      _createVNode(_component_el_menu_item, { index: "about" }, {
        default: _withCtx(() => [
          _createTextVNode(" Über uns ")
        ]),
        _: 1
      }),
      _createVNode(_component_el_menu_item, { index: "product" }, {
        default: _withCtx(() => [
          _createTextVNode(" Unser Heu ")
        ]),
        _: 1
      }),
      _createVNode(_component_el_menu_item, { index: "contact" }, {
        default: _withCtx(() => [
          _createTextVNode(" Kontakt ")
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["onSelect"]))
}