

import {Options, Vue} from "vue-class-component";
import Parallax from "./ParallaxEffect.vue";

@Options({
  components: {Parallax}
})
export default class Start extends Vue {

  private speed = 0.8;
}
